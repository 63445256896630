import { analytics, firebaseApp } from "../App";


const admin_email_adress = ["weissensteinerfelix@gmail.com", "badueber@gmail.com"];

function sendEmailWithTemplate(template: any) {
	let db = firebaseApp.firestore();
	return db.collection("mail").add(template)
		.then((docRef) => {
			return true;
		})
		.catch((error) => {
			analytics.logEvent("exception", { ErrorMsg: error.message, ErrorCode: error.code, Located: "email-function.tsx:13" });
			return false;
		});
}
function sendScheduledEmailWithTemplate(template: any) {
	let db = firebaseApp.firestore();
	return db.collection("scheduledEmails").add(template)
		.then((docRef) => {
			return true;
		})
		.catch((error) => {
			analytics.logEvent("exception", { ErrorMsg: error.message, ErrorCode: error.code, Located: "email-function.tsx:24" });
			return false;
		});
}

/**
 * Es wird eine E-Mail an den Admin versendet, wenn ein User Zugriff auf einen Spieler wünscht
 */
export async function requestAccessMail(user_name: string, user_mail: string, player_name_request_access: string) {
	let result: boolean = true;
	result = await sendEmailWithTemplate({
		to: admin_email_adress,
		template: {
			name: "RequestAccess",
			data: {
				user_name: user_name,
				user_mail: user_mail,
				player_name: player_name_request_access,
			}
		}
	})
	return await result;
}

export async function sendErrorToDev(error: string, where: string) {
	if (error && error.includes("Client is offline") || error.length <= 3)
		return await true;
	return await sendEmailWithTemplate({
		to: ['weissensteinerfelix+badminton@gmail.com'],
		message: {
			subject: 'Error from Badminton Überetsch',
			text: error,
			html: error + '\n in ' + where,
		}
	})
}

/**
 *  Es wird sofort eine E-Mail gesendet, wenn ein Admin ein neues Dokuemnt für einen Spieler hochgeladen hat.
 */
export async function newDocAvailableEmail(to: string[], player_name: string) {
	let result: boolean = true;
	for (let email of to) {
		result = await sendEmailWithTemplate({
			to: [email],
			template: {
				name: "NewDocAvailable",
				data: {
					playerName: player_name,
				}
			}
		})
		if (await result === false) {
			break;
		}
	}
	return await result;
}

/**
 * Es wird eine zeitverzögete E-Mail gesendet, wenn ein Admin ein neues Dokuemnt für einen Spieler hochgeladen hat.
 */
export async function createScheduledEmailForNewDocAvailable(to: string[], player_name: string, scheduledDate: Date) {
	let result: boolean = true;
	for (let email of to) {
		result = await sendScheduledEmailWithTemplate({
			scheduledDate,
			status: 'PENDING',
			sendgridEmail: {
				to: [email],
				template: {
					name: "NewDocAvailable",
					data: {
						playerName: player_name,
					}
				}
			}
		})
		if (await result === false) {
			break;
		}
	}
	return await result;
}


/**
 * Ein neuer User hat sich registriert und es wird eine E-Mail an den Admin versendet
 */
export async function newUserSignedUpEmail(user_name: string, user_mail: string, player_name_request_access: string) {
	let result: boolean = true;
	result = await sendEmailWithTemplate({
		to: admin_email_adress,
		template: {
			name: "newUserSignUp",
			data: {
				user_name: user_name,
				user_mail: user_mail,
				player_name: player_name_request_access,
			}
		}
	})
	return await result;
}

/**
 * Ein User hat ein neues Dokument hochgeladen und der Admin wird benachrichtigt.
 */
export async function userhasUploadedaNewDoc(user_name: string, user_mail: string, player_name: string) {
	let result: boolean = true;
	result = await sendEmailWithTemplate({
		to: admin_email_adress,
		template: {
			name: "UserhasUploadedaNewDoc",
			data: {
				user_name: user_name,
				user_mail: user_mail,
				player_name: player_name,
			}
		}
	})
	return await result;
}
